'use client'

/**
 * components/Button/Main.js
 */
import PropTypes from 'prop-types';
import { useRouter } from 'next/navigation';

const ButtonMain = (props) => {
  const {
    path,
    handler,
    isLinkOutside,
    buttonProps,
    className,
    children,
    target="_blank"
  } = props;

  const router = useRouter();

  return <>
    {handler ? (
      <button
        className={className}
        type="button"
        onClick={handler}
        {...buttonProps}
      >
        {children}
      </button>
    ) : (
      <>
        {isLinkOutside ? (
          <a
            className={className}
            href={path}
            target={target}
            rel='noreferrer'
            {...buttonProps}
          >
            {children}
          </a>
        ) : (
          (<button type='button' onClick={() => router.push(path)} className={className} {...buttonProps}>

            {children}

          </button>)
        )}
      </>
    )}
  </>;
};

ButtonMain.propTypes = {
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isLinkOutside: PropTypes.bool,
  handler: PropTypes.func,
  buttonProps: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default ButtonMain;
